<template>
  <div style="background-color: #fff; padding: 10px 5px; min-height: 100%">
    <el-button
      style="margin-bottom: 10px"
      type="primary"
      size="mini"
      @click="addAgency"
      >添加供应商
    </el-button>
    <el-table
      v-loading="loading"
      :data="campaignTableInfo"
      :header-cell-style="headerStyle"
      :cell-style="cellStyle"
      stripe
    >
      <el-table-column label="ID" type="index" align="center" />
      <el-table-column
        v-for="item in colData"
        :key="item.prop"
        :width="item.width"
        :align="item.align"
        :prop="item.prop"
        :label="item.label"
      />
      <el-table-column label="操作" align="center">
        <template #default="scope">
          <el-tooltip content="修改">
            <el-button
              type="text"
              size="mini"
              icon="el-icon-edit"
              @click="modifyAgency(scope.row)"
            >
            </el-button>
          </el-tooltip>
          <el-tooltip content="删除">
            <el-button
              type="text"
              size="mini"
              @click="deleteAgency(scope.row.id)"
              :disabled="scope.row.accountName === agencyName"
            >
              <svg-icon class-name="iconshanchu" />
            </el-button>
          </el-tooltip>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog
      width="30%"
      :title="dialogTitle"
      :close-on-click-modal="false"
      v-model="dialogVisible"
    >
      <el-form
        :model="formData"
        size="mini"
        label-width="100px"
        ref="ruleForm"
        :rules="rules"
      >
        <el-form-item label="供应商名称" prop="name">
          <el-input
            v-model.trim="formData.name"
            style="width: 217px"
          ></el-input>
        </el-form-item>
        <!-- <el-form-item label="供应商账号" prop="operators">
          <el-select
            v-model="formData.operators"
            multiple
            collapse-tags
            placeholder="请选择"
          >
            <el-option
              v-for="item in options"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item> -->
        <el-form-item label="备注">
          <el-input
            v-model.trim="formData.comments"
            style="width: 217px"
          ></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
        <el-button size="mini" @click="dialogVisible = false">取 消</el-button>
        <el-button size="mini" type="primary" @click="submitForm('ruleForm')"
          >确 定
        </el-button>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import {
  getAgencyList,
  modifyAgency,
  addAgency,
  judgeAgencyName,
  delAgency,
} from "@/api/agency";

export default {
  name: "index",
  data() {
    let checkuserName = async (rule, value, callback) => {
      if (!value) {
        callback(new Error("请输入供应商名称"));
        // 判断重名
      } else if (this.formData.agencyId) {
        const { code } = await judgeAgencyName({
          agencyName: this.formData.name,
          agencyId: this.formData.agencyId,
        });
        code ? callback(new Error("供应商名称已存在")) : callback();
      } else {
        // 添加
        const { code } = await judgeAgencyName({
          agencyName: this.formData.name,
          agencyId: "",
        });
        code ? callback(new Error("供应商名称已存在")) : callback();
      }
    };
    return {
      // 表格样式
      headerStyle: {
        backgroundColor: "#F9FCFE",
        borderTopWidth: "1px",
        borderTopColor: "#eee",
        borderTopStyle: "solid",
      },
      cellStyle: {
        fontSize: "12px",
      },
      colData: [
        {
          width: null,
          align: "center",
          prop: "agencyName",
          label: "供应商名称",
        },
        // {
        //   width: null,
        //   align: "center",
        //   prop: "accountName",
        //   label: "账号",
        // },
        {
          width: null,
          align: "center",
          prop: "comments",
          label: "备注",
        },
      ],
      campaignTableInfo: [
        {
          id: null,
          agencyName: "",
          // accountName: [],
          accountId: [],
          accounts: [],
          comments: "",
        },
      ],
      dialogVisible: false,
      rules: {
        name: [{ required: true, validator: checkuserName, trigger: "blur" }],
        operators: [
          { required: true, message: "请选择供应商账号", trigger: "blur" },
        ],
      },
      formData: {
        name: "",
        operators: [],
        comments: "",
      },
      options: [
        {
          id: "选项1",
          name: "黄金糕",
        },
        {
          id: "选项2",
          name: "双皮奶",
        },
        {
          id: "选项3",
          name: "蚵仔煎",
        },
      ],
      dialogTitle: "修改供应商",
      loading: false,
    };
  },
  computed: {
    agencyName() {
      return this.$storage.getItem("agencyName");
    },
  },
  mounted() {
    this.getAgencyList();
  },
  methods: {
    async getAgencyList() {
      this.loading = true;
      const { data } = await getAgencyList();
      data.forEach((item) => {
        // item.accountName = item.accounts
        //   .map((account) => account.name)
        //   .toString();
        item.accountId = item.accounts?.map((account) => account.id);
      });
      this.campaignTableInfo = data;
      this.loading = false;
    },
    modifyAgency(row) {
      this.openDialogClearForm(row.id);
      this.dialogTitle = "修改供应商";
      this.formData = {
        agencyId: row.id,
        name: row.agencyName,
        // operators: row.accountId,
        comments: row.comments,
      };
    },
    addAgency() {
      this.openDialogClearForm();
      this.dialogTitle = "添加供应商";
      this.formData = {
        name: "",
        // operators: [],
        comments: "",
      };
    },
    submitForm(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          if (this.formData.agencyId) {
            await modifyAgency(this.formData);
          } else {
            await addAgency(this.formData);
          }
          this.dialogVisible = false;
          await this.getAgencyList();
        }
      });
    },
    openDialogClearForm() {
      this.dialogVisible = true;
      setTimeout(() => {
        this.$refs.ruleForm.clearValidate();
      }, 20);
    },
    deleteAgency(id) {
      this.$confirm("确认删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(async () => {
        await delAgency(id);
        await this.getAgencyList();
      });
    },
  },
};
</script>

<style scoped></style>
